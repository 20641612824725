var stickyHeader = true;
// var flyoutNav = false;
// var fullscreenNav = false;
var inlineNav = true;
// var hoverSensitiveNav = false;
// var megaMenu = false;

// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video

var countUpStats = true;
// var countUpStatsDuration = 3000;

// var homeQuickGivingPreFooter = false;
var transformHomepageDonationAmountImages = true;

// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
var feedItemsCarouselBreakpointAlt = 768; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
var feedItemsCarouselAutoplayAlt = false;
var feedItemsCarouselAutoplaySpeed = 4000;

// var subsiteHijackMainNav = ['making-trax-bristol'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var subsiteTitleNotClickable = true;

// var sidebarCards = false; // Make sure to set a card layout prefab for '.associatedRelatedPosts .associatedListing' and a card prefab to '.sidebarCard'

// Insert <br /> before each .carouselSlideReadMore
$('.carouselSlideReadMore').before('<br />');

// $('.carouselSlide').each(function(){
//   $('.carouselControls').first().clone().prependTo($(this).find('.carouselSlideDetail'));
// })

$('body.homepage .carouselDetailWrapper').append('<img class="carousel-frame" src="/fresco/clients/accesssportclone/assets/carousel-frame.svg" alt="" />');
$('body:not(.homepage) .carouselDetailWrapper').append('<img class="carousel-frame" src="/fresco/clients/accesssportclone/assets/carousel-frame-inner.svg" alt="" />');

$('.homeIntroWrapper').wrap('<div class="introTextAndImg">');

$('.introTextAndImg').append('<img class="intro-frame" src="/fresco/clients/accesssportclone/assets/intro-frame.svg" alt="" />');

function resizeElements() {
  $('.intro-frame').css('width', 0);
  for (var i = 0; i < 2; i++) {
    $('.intro-frame').css('width', $('.carousel').width() / 4);
  }
}

function positionCarouselControls() {
  $('.carouselControls').css('top', document.querySelector('.carouselSlideHeading').offsetTop - $('.carouselControlPrev').height() - 50);
  $('.carouselControls').css('left', document.querySelector('.carouselSlideHeading').offsetLeft + 30);
  if (window.innerWidth < 1024) {
    // Set .carouselControls top to bottom of first .carouselSlideTitle
    $('.carouselControls').css('top', document.querySelector('.carouselSlideTitle').offsetTop + document.querySelector('.carouselSlideTitle').offsetHeight + 51);
  }
  if (window.innerWidth < 577) {
    // Set .carouselControls top to bottom of first .carouselSlideTitle
    $('.carouselControls').css('top', document.querySelector('.carouselSlideTitle').offsetTop + document.querySelector('.carouselSlideTitle').offsetHeight + 21);
  }
}

function sizeHomeQuickGivingDonateBtn() {
  // Set .donateBtn width to width of .donationAmounts
  $('.donateBtn, .homepage-quickgiving--copy > *').css('width', ($('.donationAmounts').width() - 8));
  // // If window below 1025
  // if (window.innerWidth < 1025) {
  //   $('.donateBtn, .homepage-quickgiving--copy > *').css('width', "100%");
  // }
}

function sizeBlockquotes() {
  $('blockquote').each(function () {
    $(this).css('min-height', $(this).css('width'));
  });
}

if ($('body[class*="PostBody"]').length) {
  $('.carouselSlide img').attr('srcset', '');
}

if ($('.listingIntro').length) {
  $('.listingIntro').addClass('postContent').insertBefore('.listContent')
}

if ($('body').hasClass('homepage')) {
  $('.homefeaturecategory-homeboxquickgiving .selectDonationAmount .donationAmount').wrapAll('<div class="donationAmounts">');

  $('.homefeaturecategory-homeboxquickgiving').wrap('<div class="quick-giving-wrapper">');

  $('.feedItem .readMore').before('<br />');

  // Add .feedItem to each .tweet
  $('.tweet').addClass('feedItem');

  $('document').ready(function () {
    // On each .feedItem, insert .readMore after .home-feed-image-link
    $('.feedItem:not(.tweet)').each(function () {
      $('<span class="readMore">Read more</span>').appendTo($(this));
    });
    // Adjust home box 1 snippet markup to mimic a post
    $('.homeBox1.homeFeatureSnippets').each(function () {
      $(this).find('a:has(img)').addClass('home-feature-image-link');
      $(this).children().not('.home-feature-image-link').wrapAll('<div class="homeFeatureDetailsWrapper">');
      $(this).find('.home-feature-image-link img').addClass('banner homeBoxImage');
    });
  });
}

if ($('.formDonatePage').length) {
  $('body').addClass('donatePage');
  $('.carouselSlide img').wrap('<a class="carouselSlideTitle">');
};

$('.pageHeader > *').wrapAll('<div class="pageHeaderWrapper pageHeader">');
$('.NewsletterSign-up').wrap('<section class="footerBox footerBox1 NewsletterSign-up newsletterWrapper">');

$(window).on('load scroll resize', function () {
  if ($(window).width() > 1024) {
    resizeElements();
  }
  if ($('body').hasClass('homepage')) {
    positionCarouselControls();
    sizeHomeQuickGivingDonateBtn();
  }
  if ($('body').hasClass('listing-with-feature')) {
    positionCarouselControls();
  }
  sizeBlockquotes();
});

// Custom Slick integration
// If you set a feedItemsCarouselBreakpointAlt value, the feed item cards
// will turn into a carousel at this breakpoint. This can help to reduce
// the vertical height of the homepage on mobile, although it obviously
// massively deprioritises the feed items that aren't in the first position

if (
  typeof feedItemsCarouselBreakpointAlt !== 'undefined' &&
  feedItemsCarouselBreakpointAlt > 0 &&
  ($('body').hasClass('homepage') && !$('body').hasClass('subsite'))
) {
  if (typeof feedItemsCarouselAutoplayAlt == 'undefined') {
    var feedItemsCarouselAutoplayAlt = false;
  }

  if (typeof feedItemsCarouselAutoplaySpeedAlt == 'undefined') {
    var feedItemsCarouselAutoplaySpeedAlt = 0;
  }

  if (typeof feedMegaCarousel == 'undefined') {
    var feedMegaCarousel = [];
  }

  // Set a timer variable for setTimeout usage
  var feedItemsCarouselTimer;

  // Set a variable to store the state of whether Slick has been applied
  var feedItemsCarouselInitiated = false;

  $(window).on('load resize', function () {
    clearTimeout(feedItemsCarouselTimer);

    feedItemsCarouselTimer = setTimeout(function () {
      var windowWidth = $(window).width();

      if (windowWidth <= feedItemsCarouselBreakpointAlt) {
        if (feedItemsCarouselInitiated == false) {
          $('.homeFeed').each(function (i) {
            var homeFeedBoxClass = '.homeFeedBox' + (i + 1);

            // If this home feed is not set to be a mega carousel...
            if (!feedMegaCarousel.includes(homeFeedBoxClass)) {
              $(this)
                .find('.feedList')
                .slick({
                  infinite: true,
                  slidesToScroll: 1,
                  slidesToShow: 1,
                  centerMode: true,
                  arrows: false,
                  autoplay: feedItemsCarouselAutoplayAlt,
                  autoplayspeed: feedItemsCarouselAutoplaySpeedAlt,
                  variableWidth: true
                });
            }
          });

          feedItemsCarouselInitiated = true;
        }
      } else if (feedItemsCarouselInitiated == true) {
        $('.homeFeed').each(function (i) {
          var homeFeedBoxClass = '.homeFeedBox' + (i + 1);

          // If this home feed is not set to be a mega carousel...
          if (!feedMegaCarousel.includes(homeFeedBoxClass)) {
            $(this)
              .find('.feedList')
              .slick('unslick');
          }
        });

        feedItemsCarouselInitiated = false;
      }
    }, 10);
  });
}

$('.postContent > blockquote:not(.right)').wrap('<div class="blockquoteWrapper">');

$('.postContent .right').each(function () {
  $(this).parentsUntil('.postContent').addClass('right');
})

// Custom forms
if ($('#customFormContainer').length) {
  $('body').addClass('customFormPage');
  $('#outerDiv > h1').addClass('title').insertAfter('.carousel').wrap('<div class="headerText">');
}

$('document').ready(function () {
  if ($('body').hasClass('subsite')) {
    if ($('body').hasClass('homepage')) {
      $('.headerTextSubsite').appendTo('.headerWrapperSubsite');
    }
  }
});